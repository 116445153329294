import { useState } from "react";
import BulletPoints from "./BulletPoints";

const FourthBannerContent = () => {
    const [mainBannerPoints] = useState([
        { text: "FAST ACTION BONUS #1: 100% Done-For-You 'white glove' installation service where you connect your account to our automated system, Simply enter a few details and your make money journey begins on auto pilot (Value $197)" },
        {
            text: `FAST ACTION BONUS #2: Access to TradeOnes
                    optimized settings that make our clients $1,000's
                    every week(Value $397) `
        },
        {
            text: `FAST ACTION BONUS #3: Free updates and
                    upgrades for life. Anytime we make improvements
                    to TradeOnes System, you'll get access to the latest
                    version (Value $297)`
        },
        {
            text: `FAST ACTION BONUS #4: Get first dibs and
                    steep discounts on other future TradeOnes
                    Automated Systems we release. As an existing
                    client, you'll be the FIRST to be notified before
                    everyone else (Value $597)`
        },
    ])
    return (
        <>
            <div>
                <div className="row">
                    <div className="col-12">
                        <h1>
                            <b>
                                Hurry! This Limited-Time Offer Expires Soon
                            </b>
                        </h1>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <p className="main-banner-text">
                            <b>
                                It has the potential to generate thousands of dollars in
                                passive cash flow for the rest of your life.
                            </b>
                        </p>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-12">
                        <h1>
                            <b>
                                Here's A Quick Glance At What You're Getting:
                            </b>
                        </h1>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <p className="main-banner-text">
                            The Ability to connect your account to our Automated trading system + The Following Exclusive Bonuses (Valued At Over $1,400) Totally <b><u>FREE</u></b> Of Charge 
                            <br /><br />
                            <BulletPoints bulletPoints={mainBannerPoints} />
                            <br />
                            Total Value: $1,488
                            Here's What To Do Next
                            As you can probably already tell, what I'm offering you
                            here is a <b> <u>COMPLETELY PERSONALIZED</u></b> done-for-you
                            solution you won't find anywhere else.
                        </p>

                    </div>
                </div>
                
            </div>

        </>
    );
}
 
export default FourthBannerContent;