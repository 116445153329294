import { useState } from "react";
import image1 from "../../images/articles-image.png"
import BulletPoints from "./BulletPoints";
const ThirdBannerContent = () => {
    var [images] = useState([
        {
            image: image1,
            text: "this is the text"
        },
    ]);

    const [mainBannerPoints] = useState([
        { text: "Run 24/5 without taking coffee breaks" },
        { text: "Never call in sick" },
        { text: "Never ask for a raise" },
        { text: "Never complain" },
        { text: "Never ask for vacation time" },
        { text: "Doesn't want a 'Benefits' Plan" },
        { text: "And will ALWAYS follow a proven plan to predictably" },
        { text: "and consistently deliver you profits" },
    ])
    return (
        <>
            <div>
                <br />
                <br/>
                <div className="row">
                    <div className="col-12">
                        <p style={{fontSize: '28px'}}>
                            <b>It will: </b>
                        </p>
                        
                        <BulletPoints bulletPoints={mainBannerPoints}/>
                        
                    </div>
                </div>
                <div className="row">
                    {images.map((image, index)=>(
                        <div className="col-4" key={index}>
                            <br />
                            <div className="row">
                                <div className="col">
                                    <img src={image.image} alt="thirdBanner" className="thirdBannerImages" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
}
 
export default ThirdBannerContent;