import { useState } from "react";

const BoxPointsContainer = () => {
    const [boxPoints] = useState([
        {
            float: 'left',
            text: `It's recession-proof and inflation-proof.
                    Because it can generate gains in any market condition.
                    Like I said, I've already done all the hard work for you.`,
        },
        {
            float: 'right',
            text: `There's nothing for you to "figure out".
                    One quick warning though...
                    Not all trading systems are created equal.`,
        },
        {
            float: 'left',
            text: `Finding a well-designed system that's easy to use and
                    profitable over time is like finding a diamond in a landfill
                    garbage dump.
                    In other words, extremely rare.`,
        },
        {
            float: 'right',
            text: `Even if you've heard about Forex trading systems before
                    (or have tried them in the past without success), you
                    probably haven't seen things done like this before.`,
        },
    ])
    return (
        <section className="box-points-container">
            <br />
            <div className="container">
                <center>
                    <div className="row">
                        <div className="col-12" style={{fontWeight: 'bold', textDecoration: 'underline'}}>
                            <h1>Your Money Is Always Working</h1>
                        </div>
                    </div>
                </center>
                <div className="row box-points-row">
                    {boxPoints.map((boxPoint, index)=>(
                        <div className="col-lg-6 col-12" key={index}>
                            <div style={{float: boxPoint.float}} className="box-points">
                                <div className="row">
                                <div className="col-1" style={{position: 'relative'}}>
                                    <span className="box-points-ticks" />
                                </div>
                                <div className="col">{boxPoint.text}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <br /><br />
        </section>

    );
}
 
export default BoxPointsContainer;