const UnlockOffer = (props) => {
    const {colClass} = props

    function handleClick(args) {
        window.location.href = "https://hy.page/tradeones";
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="main-banner-rating-line">
                        <span className="main-banner-star">★</span>
                        <span className="main-banner-star">★</span>
                        <span className="main-banner-star">★</span>
                        <span className="main-banner-star">★</span>
                        <span className="main-banner-star">★</span>
                        <span className="main-banner-rating-text">Verified Customer Reviews</span>
                    </div>
                </div>
            </div>
            <br />
            <div className="row">
                <div className={colClass}>
                    <button className="btn btn-success main-banner-unlock-offer" onClick={handleClick}>Get started <span className="main-banner-arrow">➤</span></button>
                </div>
            </div>
        </>
    );
}
 
export default UnlockOffer;