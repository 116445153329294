import { useState } from "react";
import BulletPoints from "./BulletPoints";

const MainBannerContent = () => {
    const [mainBannerPoints] = useState([
        { text: "Identifies winning trades based on decades of research." },
        { text: "Quick set up - Set IT & Forget IT  Forex Trading System" },
        { text: "Average Past Performance Profit 10% - 20% /per month" },
        { text: "Money Management" },
        { text: "Risk Management With AI" },
        { text: "A proven, reliable cash-generating system" },
        { text: "You can run the TradeOnes Automated Trading system from anywhere in the world" },
        { text: "You don't have to do any research, any technical analysis, or have to place trades on your own." },
        { text: "It doesn't matter if the markets are up, down, sideways, or even crashing." },
    ])
    return (
        <>
        <div className="row">
            <div className="col-lg-8 col-12" style={{ fontWeight: 'bold' }}>
                <h1>What is TradeOnes?</h1>
            </div>
        </div>
        <br />
        <div className="row">
            <div className="col-12">
                    <p className="main-banner-text">TradeOnes is a Forex Trading System. Using dynamic algorithms adapting to different market conditions in this 
                        ever changing market.
                        <br /> <b> WE ARE THE FUTURE OF TRADING.</b> <br/>
                        Trade Faster. Trade Smarter. We’ll Do The Hard Part. Get Ready To Boost Your Profits And Outperform The Markets.
                        <br /><b>HERE TO HELP YOU INVEST WITH CONFIDENCE.</b><br />
                        Work with our Trading system to get a balanced plan designed to withstand volatile markets and get's You to your goals.</p>
            </div>

        </div>
        <BulletPoints bulletPoints={mainBannerPoints} />
        </>
    );
}
 
export default MainBannerContent;