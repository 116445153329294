const QuestionContent = (props) => {
    const { image, paraOne, paraTwo, headingText, headingCounter } = props;
    return (
        <div className="container question-container">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="question-header"> <span className="question-header-counter">{headingCounter}</span> <span className="question-header-text">{headingText}</span></h2>
                </div>
            </div>
            <div className="row" style={{padding: '16px'}}>
                <div className="col-md-8 order-lg-1 order-2">
                    <p className="question-text">{paraOne}</p>
                </div>
                <div className="col-md-4 order-lg-2 order-1">
                    <img
                        src={image} // Replace with your image source
                        alt="Sample"
                        className="question-image"
                    />
                </div>
                <div className="col-12 order-3">
                    <p className="question-text">{paraTwo}</p>
                </div>
            </div>
        </div>
    );
};

export default QuestionContent;