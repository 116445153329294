import React, { Component } from 'react'
import logo from "../images/logo.png";
import video from "../videos/main-videos.mp4"

export class MainContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            strings: ["Forex Trading Account"],
            currentIndex: 0,
            currentText: '',
            isDeleting: false,
            typingSpeed: 120, // Adjust typing speed here (lower value means faster typing)
            pauseDuration: 5000, // Time to pause before deleting the word
            bulletPoints: [
                "Insanely <b><u>profitable automated system</u></b> I developed that generates daily passive recurring income for my clients and me.",
                "<span style='color: red;'><b><u>Start making money in less than 24 hours</u></b></span> from now without any special knowledge or experience.",
                "This is about making <b><u>'work-free' passive income</u></b>.",
                "My silent little money machine generated over <span style='color: red;'><b><u>60% in passive profits</u></b></span> with a total of 0 minutes of work (yes ZERO).",
                "The only reason it's possible is because I've spent <b><u>the last 5 year's of my life (and thousands of dollars)</u></b> developing automation technology that everyday people (just like you) can profit from.",
                // "Knowing the difference between something that will MAKE you money vs. one that will COST you money is absolutely critical to your success. And that's where you're going to benefit from my two+ decades of in-the-trenches experience.",
                // "Because I've already done all the 'PIA' (pain in the ass) detective work for you. You don't need to spend the 1000s of hours I did.",
                // "You just need a proven, reliable cash-generating system.",
                // "As long as you have access to the Internet, you can run the TradeOnes Automated Trading system from anywhere in the world.",
                // "You don't have to do any research, any technical analysis, or have to place trades on your own. And it doesn't matter if the markets are up, down, sideways, or even crashing."
            ],
        };
    }
    componentDidMount() {
        this.type();
    }

    type = () => {
        const { strings, typingSpeed, pauseDuration } = this.state;
        const { currentIndex, currentText, isDeleting } = this.state;

        const word = strings[currentIndex];

        if (isDeleting) {
            this.setState({ currentText: word.substring(0, currentText.length - 1) });
        } else {
            this.setState({ currentText: word.substring(0, currentText.length + 1) });
        }

        if (!isDeleting && currentText === word) {
            this.setState({ isDeleting: true });

            setTimeout(() => {
            this.setState((prevState) => ({
                isDeleting: false,
                currentIndex: (prevState.currentIndex + 1) % strings.length,
            }));
            }, pauseDuration);
        } else if (isDeleting && currentText === '') {
            this.setState((prevState) => ({
                isDeleting: false,
                currentIndex: (prevState.currentIndex + 1) % strings.length,
            }));
        }

        setTimeout(this.type, isDeleting ? typingSpeed / 2 : typingSpeed);
    };

    
  render() {
    return (
        <div className="main-container">
            <div className="logo">
                <img src={logo} style={{maxWidth: 250}} alt="Logo" />
            </div>
            <br /><br /><br />
            <center id="landingText">
                <div>
                    <h4 className="text-center encapsulated-line">
                        The Easiest, Most Effortless, Never-Ending Stream of
                        Passive Cashflow You'll Ever Make
                    </h4>
                    <br /><br />
                    <h1 className="text-center">
                        Connect your
                        <span id="typewriter">{this.state.currentText}</span>
                        <span className="remainingText">
                            <br />To
                            Make Hands-Free
                            <span style={{color: '#0CC0DF'}}> Passive Income Daily </span>
                            Using Our <span style={{textDecoration: 'underline'}}> "Automated
                            Trading System" </span> That
                            Does All The Work For
                            You...
                        </span>
                    </h1>
                </div>

            </center>
            <br />
            <div className="sub-container" id="video-bulletpoints-main">
                <div className="video-container">
                    <video className="video" controls>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                </div>
                <div className="bullet-points">
                    <ul className="bullet-list">
                        {this.state.bulletPoints.map((bulletPoint, index)=>(
                            // console.log(bulletPoint)
                            <li key={index} dangerouslySetInnerHTML={{__html: bulletPoint}} />
                        ))}
                    </ul>
                </div>
            </div>
        </div>

    )
  }
}

export default MainContainer