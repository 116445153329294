import MainBannerContent from "./MainBannerContent";
import SecondBannerContent from "./SecondBannerContent";
import ThirdBannerContent from "./ThirdBannerContent";
import FourthBannerContent from "./fourthBannerContent";
import UnlockOffer from "./UnlockOffer";
import TopHeading from "./TopHeading";

const MainBanner = (props) => {
    const { image, image2, video, content, isTopHeading, isOfferCenter, customStyle} = props;
    return (
        <div className="container-fluid main-banner" style={customStyle}>
            <div className="container">
                <br />
                {isTopHeading &&
                    <TopHeading 
                        heading={"It's like having your own silent employee making money for you around the clock, 24 hours per day / 5 days a week / 365 days a year."}
                    />
                }
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <center>
                            <img src={image} alt="logo" className="main-banner-image" />

                            {image2 && <img src={image2} alt="logo" className="main-banner-image2" />}

                            {video && 
                                <div className="video-container">
                                    <video className="video" controls>
                                        <source src={video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            }
                        </center>
                    </div>
                    <div className="col-lg-6 col-12">
                        {
                            content === "mainBannerContent" && 
                            <MainBannerContent />
                        }
                        {
                            content === "secondBannerContent" &&
                            <SecondBannerContent />
                        }
                        {
                            content === "thirdBannerContent" &&
                            <ThirdBannerContent />
                        }
                        {
                            content === "fourthBannerContent" &&
                            <FourthBannerContent />
                        }
                        <br />
                        {
                            !isOfferCenter &&
                            <UnlockOffer colClass="col-lg-8 col-12" />
                        }
                    </div>
                </div>
                <center>
                    {
                        isOfferCenter &&
                        <div className="row">
                            <div className="col-4 d-lg-block" style={{display: 'none'}}></div>
                            <div className="col-lg-4 col-12">
                                <UnlockOffer colClass="col-12" />
                            </div>
                            <div className="col-4 d-lg-block" style={{display: 'none'}}></div>
                        </div>
                    }
                </center>
                <br /><br />
            </div>
        </div>


    );
}
 
export default MainBanner;