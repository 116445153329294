const TopHeading = (props) => {
    const {heading, para} = props;
    return (
        <center>
            <div className="row">
                <div className="col-12" style={{ color: 'black', fontWeight: "bold", textDecoration: 'underline' }}>
                    <h1>{heading}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <p style={{whiteSpace: "pre-wrap"}}>{para}</p>
                </div>
            </div>
        </center>
    );
}
 
export default TopHeading;