import React, { Component } from 'react'
import cardsImage from "../images/cardsImage.png";

export class TimerMain extends Component {
    constructor(props){
        super(props);
        this.state = {
            days: 0,
            hours: 0,
            minutes: 60,
            seconds: 0,
            totalTimeInSeconds: 0,

            cards: {
                image: cardsImage,
                class: "payment-logos",
                alt: "Master Card",
            }
        };
    }

    componentDidMount() {
      this.calculateTotalTimeInSeconds();
      this.startTimer();
    }

    calculateTotalTimeInSeconds = () => {
        const { days, hours, minutes, seconds } = this.state;
        const totalTimeInSeconds = (days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60) + seconds;
        this.setState({ totalTimeInSeconds });
    };

    startTimer = () => {
        this.timerInterval = setInterval(() => {
            this.setState(
            prevState => ({ totalTimeInSeconds: prevState.totalTimeInSeconds - 1 }),
            () => {
                const { totalTimeInSeconds } = this.state;

                if (totalTimeInSeconds <= 0) {
                    clearInterval(this.timerInterval);
                    // Update your component's state or perform any actions here
                    return;
                }

                const remainingDays = Math.floor(totalTimeInSeconds / (24 * 60 * 60));
                const remainingHours = Math.floor((totalTimeInSeconds % (24 * 60 * 60)) / (60 * 60));
                const remainingMinutes = Math.floor((totalTimeInSeconds % (60 * 60)) / 60);
                const remainingSeconds = totalTimeInSeconds % 60;

                this.setState(
                    {days: remainingDays}
                )
                this.setState(
                    {hours: remainingHours},
                )
                this.setState(
                    {minutes: remainingMinutes},
                )
                this.setState(
                    {seconds: remainingSeconds},
                )

                // Update your component's state or perform any actions here
            }
            );
        }, 1000);
    };

    componentWillUnmount() {
        clearInterval(this.timerInterval);
    }

    handleClick(args){
        window.location.href = "https://hy.page/tradeones";
    }
  render() {
    return (
    
        <div className="click-here-timer-main">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <center>
                            <br />
                            <h3 style={{justifyContent: 'center'}}>Completely <b><u>automate your trading</u></b> with the most lethal institutional trading algo in the market</h3>
                            {/* <h3>Click Below Now</h3> */}
                            <p style={{fontSize: 24}}>Just Pay $15/mo Instead Of <span className="line-over-text">$297/mo</span></p>
                            <br />
                            <button className="click-here-button" onClick={this.handleClick}>
                                <h1>Click Here Now To Get Started</h1>
                            </button>
                            <div className="row click-here-cards" style={{marginTop: 10}}>
                                <div className="col">
                                    <img src={this.state.cards.image} className={this.state.cards.class} alt={this.state.cards.alt} />
                                </div>
                            </div>
                        </center>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="hurry-main">
                        <span style={{color: 'red'}}>Hurry!</span>
                            <span> This Limited-Time Offer Expires Soon </span>
                        </div>
                        <br />
                        <div id="timer">
                        
                        {this.state.totalTimeInSeconds > 0 &&
                        
                            <div className="row">
                                <div className="col-2">
                                <div className="row">
                                    <div className="col timer-counter" id="timer-days">
                                    {this.state.days < 10 ? '0'+this.state.days : this.state.days}
                                    </div>
                                </div>
                                <div className="row timer-title">
                                    <div className="col">
                                    Days
                                    </div>
                                </div>
                                </div>
                                <div className="col-1">
                                <div className="row">
                                    <div className="col timer-counter">
                                    :
                                    </div>
                                </div>
                                </div>
                                <div className="col-2">
                                <div className="row">
                                    <div className="col timer-counter" id="timer-hours">
                                        {this.state.hours < 10 ? '0'+this.state.hours : this.state.hours}
                                    </div>
                                </div>
                                <div className="row timer-title">
                                    <div className="col">
                                    Hours
                                    </div>
                                </div>
                                </div>
                                <div className="col-1">
                                <div className="row">
                                    <div className="col timer-counter">
                                    :
                                    </div>
                                </div>
                                </div>
                                <div className="col-2">
                                <div className="row">
                                    <div className="col timer-counter" id="timer-minutes">
                                        {this.state.minutes < 10 ? '0'+this.state.minutes : this.state.minutes}
                                    </div>
                                </div>
                                <div className="row timer-title">
                                    <div className="col">
                                    Minutes
                                    </div>
                                </div>
                                </div>
                                <div className="col-1">
                                <div className="row">
                                    <div className="col timer-counter">
                                    :
                                    </div>
                                </div>
                                </div>
                                <div className="col-2">
                                <div className="row">
                                    <div className="col timer-counter" id="timer-seconds">
                                        {this.state.seconds < 10 ? '0'+this.state.seconds : this.state.seconds}
                                    </div>
                                </div>
                                <div className="row timer-title">
                                    <div className="col">
                                    Seconds
                                    </div>
                                </div>
                                </div>
                            </div>
                        }
                        </div>
                        <br />
                        <div className="commercial">
                        <b>
                            1 time low price ends
                            when the timer hits Zero
                        </b>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      
    )
  }
}

export default TimerMain