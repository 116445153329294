const SecondBannerContent = () => {
    return (
        <>
            <div>
                <div className="row">
                    <div className="col-12">
                        <h1>
                            <b>
                                It's Actually A Freedom
                                Machine That Can Generate
                                Passive Income For You For
                                Life
                            </b>
                        </h1>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <p className="main-banner-text">
                            So you're left with just a bona fide money making machine
                            that's newbie-friendly.
                            So people with ZERO tech skills can jump in and start
                            profiting from Day 1 with a ZERO learning curve.
                            Perfect if you have a 9-5.
                        </p>
                    </div>
                </div>
                {/* <div className="articles-point-to-ponter">
                    <div className="row">
                        <div className="col-10">
                            <p className="articles-top-para articles-point-to-ponter-text">
                                So when I say this is easy, I'm making a big
                                understatement.
                            </p>
                        </div>
                        <div className="col-2">
                            <img src="https://www.tommychongshemp.com/ten-things/app/desktop/images/index-images/billbook.png" alt="point to ponder" className="articles-point-to-ponter-image" />
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-12">
                        <p className="main-banner-text">
                            <b><u>
                                So when I say this is easy, I'm making a big
                                understatement.
                            </u></b>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p className="main-banner-text">
                            Once you realize how powerful this software is, you're
                            never going to look at making money online the same way
                            again.
                            It's the closest thing to automated, recession-proof
                            cash-flow you'll EVER find.
                        </p>
                    </div>
                </div>
                <br /><br />
            </div>

        </>
    );
}
 
export default SecondBannerContent;