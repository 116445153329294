const BulletPoints = (props) => {
    const { bulletPoints } = props
    return (
        <div className="row">
            <div className="col">
                <div className="list-group main-banner-tick">
                    {bulletPoints.map((bulletPoint, index) => (
                        <span className="main-banner-item" key={index}>{bulletPoint.text}</span>
                    ))}
                </div>
            </div>
        </div>
    );
}
 
export default BulletPoints;