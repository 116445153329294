import offerRightImage from "../images/offer-right-image.png";
import offerLeftImage from "../images/offer-left-image.png";
import UnlockOffer from "./MainBanner/UnlockOffer";
const OfferBanner = () => {
    return (
        <section className="offer-banner">
            <br />
            <div className="container-fluid">
                <center>
                <div className="row">
                    <div className="col-lg-3 col-12" />
                    <div className="col-lg-6 col-12" style={{color: 'black', fontWeight: 'bold'}}>
                        <h3>
                            We Have an <u>Automated
                            Trading System</u> That Makes
                            <u> Passive Profits</u> Nearly Every
                            Day (And So Can You)
                        </h3>
                    </div>
                    <div className="col-lg-3 col-12" />
                </div>
                <div className="row">
                    <br /><br /><br />
                    <div className="col-lg-4 col-12 d-lg-block" style={{display: 'none'}}>
                        <img src={offerLeftImage} className="offer-banner-left-image" alt="offer banner" />
                    </div>
                    <div className="col-lg-4 col-12">
                        <br />
                        <UnlockOffer colClass="col-12" />
                    </div>
                    <div className="col-lg-4 col-12 d-lg-block" style={{display: 'none'}}>
                        <img src={offerRightImage} className="offer-banner-right-image" alt="offer banner" />
                    </div>
                </div>
                </center>
            </div>
            <br />
        </section>

    );
}
 
export default OfferBanner;