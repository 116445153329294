import TopHeading from "../MainBanner/TopHeading";
import QuestionContent from "./QuestionContent";
import UnlockOffer from "../MainBanner/UnlockOffer";
import image1 from "../../images/question-image-1.png";
import image2 from "../../images/question-image-2.png";
import { useState } from "react";

const Question = () => {
    const [questions, setQuestions] = useState([
        {
            question: "Here's Why The Ticket Price To This System Is Irrelevant",
            paraOne1: `You get to keep 50% of the profits Start with as little as $500 to $1,000 15% to 30%+ Monthly ROI potential Your money is always under your control - withdraw your funds anytime You can fund your account with crypto and withdraw to crypto Requires no trading skills or experience No sponsoring or recruiting required – everyone earns passively Profit in any economy whether the market goes up or down Available worldwide Your money is held in your forex broker account - no fake pooled ponzi accounts Create a secondary income stream - perfect if you have a 9 to 5 Simple plug-and-play passive investment income system that works for creating weekly cashflow`,
            paraTwo1: ``,
            image: image1,
        },
        {
            question: "Who This Is For?",
            paraOne1: `This is for you if you fall into one of the following three camps:
1. You like the idea of TRUE passive income (working ZERO hours per week), and want to start enjoying it as soon as 24 hours from now.
`,
            paraTwo1: `2. You want an income source that doesn't depend on you recruiting, selling, or signing people up to join you in an MLM or affiliate program, but don't know where to start. Or, you're tired of rugpulls and HYIP platforms that shutdown and disappear with your money.
3. You want a better "lifestyle" (more freedom, less stress, more time with family, vacation time, retirement income, etc.) without interfering with your full-time gig. Maybe you make good money and want to make more, but you're too damn busy to enjoy it. If you fall into any of these three camps, I'm confident I can help you, just like I've helped over 497 other people get started with this system. I'm proud to say many of them have made their first passive profits online ever.`,
            image: image2,
        },
    ])


    return (
        <>
            <section className="container-fluid questions">
                <br/>
                <div className="container">
                    <TopHeading
                        heading={"It's The 'Push Button Money' Online Opportunities Always Promised But Could Never Deliver."}
                    />
                    {questions.map((question,index)=>(
                        <QuestionContent
                            key={index}
                            image={question.image}
                            paraOne={question.paraOne1}
                            paraTwo={question.paraTwo1}
                            headingText={question.question}
                            headingCounter={index+1}
                        />
                    ))}
                    <br />
                    <div className="row">
                        <div className="col-4 d-lg-block" style={{ display: 'none' }}></div>
                        <div className="col-lg-4 col-12">
                            <UnlockOffer colClass="col-12" />
                        </div>
                        <div className="col-4 d-lg-block" style={{ display: 'none' }}></div>
                    </div>
                    <br /><br /><br />
                </div>
            </section>
        </>
    );
}
 
export default Question;