import MainContainer from "./components/MainContainer";
import TimerMain from "./components/TimerMain";
import StepsContainer from "./components/StepsContainer";
import MainBanner from "./components/MainBanner/MainBanner";
import SixItems from "./components/SixItems";
import BoxPointsContainer from "./components/BoxPointsContainer";
import OfferBanner from "./components/OfferBanner";
import Question from "./components/Questions/Question";
import TwoRows from "./components/TwoRows";

import logoBig from "./images/logo-book.jpeg"
import mainBannerImage2 from "./images/main-banner-image2.png";
import video from "./videos/Tradeones.mp4";

function App() {
  return (
    <div className="App">
      <MainContainer />
      <TimerMain />
      <StepsContainer />
      <MainBanner image={logoBig} image2={mainBannerImage2} content="mainBannerContent" />
      {/* <SixItems /> */}
      <BoxPointsContainer />
      <OfferBanner />
      <MainBanner image={logoBig} content="secondBannerContent" />
      <MainBanner image={logoBig} content="thirdBannerContent" isTopHeading={true} isOfferCenter={true} customStyle={{backgroundColor: 'white'}} />
      <Question />
      <TwoRows />
      <MainBanner image={logoBig} video={video} content="fourthBannerContent" />
    </div>
  );
}

export default App;
