import image1 from "../images/logo-book.jpeg";
import image2 from "../images/two-rows-image-2.jpg";
import UnlockOffer from "./MainBanner/UnlockOffer";
const TwoRows = () => {
    return (
        <section className="container-fluid">
            <div className="container two-rows">
                <center>
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <img src={image1} alt="" className="two-rows-image" />
                        </div>
                        <div className="col-lg-6 col-12" style={{textAlign: 'left'}}>
                            <h1 style={{ fontWeight: 'bold' }}>Getting Started Is As Easy As 1, 2, 3</h1>
                            <p className="main-banner-text" style={{ fontWeight: 'bold' }}>{'Step #1 Sign up to the forex broker of your choice'}</p>
                            <p className="two-rows-text main-banner-text">{`Your money stays in your account no fear of having some company control your money, withdraw your funds at any time. You're in control of your money at all times. Fill out an online broker account application (takes 10 minutes or less) Then, either open a free 'Demo' account, or deposit money into a live account (via crypto or Credit , debit or ACH bank wire). `}</p>
                        </div>

                        <div className="col-lg-6 col-12" style={{ textAlign: 'left' }}>
                            <p className="main-banner-text" style={{ fontWeight: 'bold' }}>{'Step #2 - Pay $15 a month plus 50% of the profits'}</p>
                            <p className="two-rows-text main-banner-text">{`Connect your Forex trading account to the passive profits money generating system, So you can start generating profits THIS WEEK.`}</p>
                            <p className="main-banner-text" style={{ fontWeight: 'bold' }}>{'Step #3 – Connect your account to start making money as soon as the next business day'}</p>
                            <p className="two-rows-text main-banner-text">{`Once your account is connected, it starts spitting out profits (usually within the first 24 hours or less). Check your account as often as you'd like on your phone or computer to see how your money is growing. Then sit back, relax, and withdraw your profits every week, or whenever you prefer. Your time is freed up to do whatever you want to do. You can be making money at the beach, out on the golf course, on a cruise ship... and yes, even while you're sleeping.`}</p>
                        </div>
                        <div className="col-lg-6 col-12">
                            <img src={image2} alt="" className="two-rows-image-2" />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-4 d-lg-block" style={{ display: 'none' }}></div>
                        <div className="col-lg-4 col-12">
                            <UnlockOffer colClass="col-12" />
                        </div>
                        <div className="col-4 d-lg-block" style={{ display: 'none' }}></div>
                    </div>
                    <br /><br /><br />
                </center>
            </div>
        </section>
    );
}
 
export default TwoRows;