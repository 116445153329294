import { useState } from "react";
import stepImage1 from "../images/steps-image-1.jpg";
import monitor from "../images/monitor.png";

const StepsContainer = () => {
    const [steps] = useState([
        {
            image: stepImage1,
            step: "Step # 1",
            title: "How to Create a Forex Account with our preferred Broker of choice.",
            description: "Watch the Video and follow the steps to create your Forex Trading account",
            imageFirst: true,
        },
        {
            image: stepImage1,
            step: "Step # 2",
            title: "Sign up & Pay $15 per month",
            description: "Subscribe today for $15 per month.+ 50% of profits every 30 days",
            imageFirst: false,
        },
        {
            image: stepImage1,
            step: "Step # 3",
            title: "Register / Login - Once Paid $15 per month",
            description: "Click this link once you have paid the $15 per month / Connect Your Account to the Trading System",
            imageFirst: true,
        },
        {
            image: stepImage1,
            step: "Step # 4",
            title: "Trading Performance",
            description: "Past performance is not indicative of future results.",
            imageFirst: false,
        },
    ]);

    return (
        <div className="container" id="stepsContainer">
            {steps.map((step, index)=>(

                step.imageFirst ?
                <div className="row" key={index}>
                    <div className="col steps">
                        <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="step-image">
                                <img alt="step images" src={monitor} className="steps-image" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 step-text">
                            <br />
                            <div className="row">
                            <div className="col">
                                <div className="step-text-heading">
                                {step.step} 
                                </div>
                            </div>
                            </div>
                            <br />
                            <div className="row" style={{paddingLeft: 10}}>
                            <div className="col-12">
                                <h2>{step.title}</h2>
                            </div>
                            <div className="col-12">
                                <p className="step-text-description">{step.description}</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                :

                <div className="row" key={index}>
                    <div className="col steps">
                        <div className="row">
                            <div className="col-12 col-lg-6 step-text order-lg-1 order-2">
                                <br />
                                <div className="row">
                                <div className="col">
                                    <div className="step-text-heading">
                                    {step.step} 
                                    </div>
                                </div>
                                </div>
                                <br />
                                <div className="row" style={{paddingLeft: 10}}>
                                <div className="col-12">
                                    <h2>{step.title}</h2>
                                </div>
                                <div className="col-12">
                                    <p className="step-text-description">{step.description}</p>
                                </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 step-image order-lg-2 order-1">
                                <img alt="step images" src={monitor} className="steps-image" />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <br /><br />
        </div>
    );
}
 
export default StepsContainer;